import { Component, Vue } from 'vue-property-decorator'

import { Link } from '@component/Link'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Footer',
  components: { Link },
  template: `
    <footer id="footer" class="footer" >
      <span class="d-block d-lg-inline">© {{year}} - Radio 357</span>

      <Link class="pl-lg-3" target="https://radio357.pl/regulamin" :newTab="true">
        <span>{{ $t("_.regulations") }}</span>
      </Link>

      <Link class="pl-3" target="https://radio357.pl/polityka-prywatnosci" :newTab="true">
        <span v-html="$t('_.privacy-policy')"></span>
      </Link>
    </footer>
  `,
})
export class Footer extends Vue {
  public year = new Date().getFullYear()
}
