import { Theme, SizeMap, SizeRegistry, ThemeRegistry } from '../_composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const spinnerSizeRegistry: SizeRegistry = {
  [SizeMap.Large]: 'lg',
  [SizeMap.Medium]: '',
  [SizeMap.Small]: 'sm',
  [SizeMap.XLarge]: 'lg',
  [SizeMap.XSmall]: 'sm',
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const spinnerThemeClassRegistry: ThemeRegistry<Theme> = {
  [Theme.Default]: 'text-body',
  [Theme.Danger]: 'text-danger',
  [Theme.Dark]: 'text-dark',
  [Theme.Info]: 'text-info',
  [Theme.Light]: 'text-light',
  [Theme.Primary]: 'text-primary',
  [Theme.Success]: 'text-success',
  [Theme.Warning]: 'text-warning',
}
