// Copyright © 2021 Move Closer

import {
  ConnectorMiddleware,
  FoundResource,
  Headers,
  Injectable,
  IResponse,
  Payload,
} from '@movecloser/front-core'

import { IIdentityService } from './identity.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class IdentityMiddleware implements ConnectorMiddleware {
  private readonly identity: IIdentityService

  constructor (identity: IIdentityService) {
    this.identity = identity
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public afterCall (response: IResponse): void {
    //
  }

  public beforeCall (resource: FoundResource, headers: Headers, body: Payload): { body: Payload; headers: Headers } {
    if (!this.identity.hasSignature()) {
      return { body, headers }
    }

    return {
      body,
      headers: {
        ...headers,
        'X-Viewer': this.identity.getSignature(),
      },
    }
  }
}
