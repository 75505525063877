import { Component, Prop, Vue } from 'vue-property-decorator'

import { Icon, IconName } from '@component/Icon'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Picture',
  components: { Icon },
  template: `
    <div>
      <img v-if="!fileLoadError" :src="source" :srcset="srcset" :alt="alt" class="img-fluid d-block"
           @error="fileLoadError = true"/>

      <div v-else class="fallback-image">
        <Icon :name="iconName" size="large" />
      </div>
    </div>
  `,
})
export class Picture extends Vue {
  @Prop({ type: String, required: false, default: '' })
  public readonly alt!: string

  @Prop({ type: String, required: true })
  public readonly source!: string

  @Prop({ type: String, required: false })
  public readonly srcset?: string

  public fileLoadError = false
  public readonly iconName = IconName.Image
}
