// Copyright © 2021 Move Closer

import { IRadioUser } from '@module/voting/contracts'

export const AuthRepositoryType = Symbol.for('AuthRepository')

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface IAuthRepository {
  check (token: string): Promise<IRadioUser>
}
