// Copyright © 2021 Move Closer

import browserSignature from 'browser-signature'
import { Injectable, WindowService } from '@movecloser/front-core'

import { IIdentityService } from './identity.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class IdentityService implements IIdentityService {
  protected signature: string = ''
  protected readonly signatureKey: string = 'x-viewer'

  public generateSignature (): void {
    if (!WindowService.isDefined) {
      return
    }

    let foundValue: string | null = this.retrieve()

    if (!foundValue) {
      foundValue = browserSignature() as string
      this.store(foundValue)
    }

    this.signature = foundValue
  }

  public getSignature (): string {
    if (!this.hasSignature()) {
      this.generateSignature()
    }

    return this.signature
  }

  public hasSignature (): boolean {
    return this.signature.length > 0
  }

  protected retrieve (): string | null {
    return window.localStorage.getItem(this.signatureKey) || window.sessionStorage.getItem(this.signatureKey)
  }

  protected store (value: string): void {
    window.localStorage.setItem(this.signatureKey, value)
    window.sessionStorage.setItem(this.signatureKey, value)
  }
}
