import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  Theme,
  getSizeClass,
  getThemeClass,
  Size,
  SizeMap,
} from '../_composables'
import { IconProps, Icon } from '../Icon'
import { Spinner } from '../Spinner'

import { buttonSizeRegistry, buttonThemeClassRegistry, buttonVariantClassRegistry } from './Button.config'
import { ButtonVariant } from './Button.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Button',
  components: { Icon, Spinner },
  template: `
    <button type="button" class="btn" :class="[sizeClass, styleClass, { loading }]" :disabled="loading || disabled"
            @click="$emit('click')">
    <slot>
      <div class="d-flex align-items-center justify-content-center">
        <Spinner v-if="loading" size="small" :class="marginClass" />
        <Icon v-else-if="icon" v-bind="{ ...icon }" :class="marginClass" />
        <span v-if="label" :class="{ 'd-none d-sm-inline': icon && !alwaysWithLabel }">{{ label }}</span>
      </div>
    </slot>
    </button>
    `,
})
export class Button extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly alwaysWithLabel!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Object, required: false, default: null })
  public readonly icon!: IconProps | null

  @Prop({ type: String, required: false, default: '' })
  public readonly label!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  @Prop({ type: String, required: false, default: SizeMap.Medium })
  private readonly size!: Size

  @Prop({ type: String, required: false, default: Theme.Default })
  private readonly theme!: Theme

  @Prop({ type: String, required: false, default: ButtonVariant.Default })
  private readonly variant!: ButtonVariant

  public get marginClass (): string {
    if (this.alwaysWithLabel && this.label) {
      return 'mr-3'
    }
    return 'mr-0 mr-sm-3'
  }

  public get sizeClass (): string {
    return getSizeClass(this.size, buttonSizeRegistry)
  }

  public get styleClass (): string {
    return this.variant === ButtonVariant.None ? `btn${this.variantPart}` : `btn${this.variantPart}${this.themePart}`
  }

  private get themePart (): string {
    return getThemeClass<Theme>(this.theme, buttonThemeClassRegistry)
  }

  private get variantPart (): string {
    return getThemeClass<ButtonVariant>(this.variant, buttonVariantClassRegistry)
  }
}
