// Copyright © 2021 Move Closer

import { Token as TokenData, Repository, IResponse, ResourceActionFailed } from '@movecloser/front-core'

import { IRadioUser, IRadioUserRepository } from '@module/voting/contracts'

// import { ConnectorErrors, resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { IAuthRepository } from '../contracts'

/**
 * Authorisation repository
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export class AuthRepository extends Repository<TokenData> implements IAuthRepository, IRadioUserRepository {
  protected useAdapter = false

  // protected errorMessage: string = 'Nie można wykonać żądania'

  public async check (token: string): Promise<IRadioUser> {
    // try {
    const response: IResponse = await this.connector.call(
      'auth',
      'check',
      {},
      {},
      { Authorization: `Bearer ${token}` },
    )

    // if (!response.isSuccessful()) {
    //   throw new ResourceActionFailed(
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     response.message,
    //     resolveFromStatus(response),
    //     { response }
    //   )
    // }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore\
    return response.data.data
    // } catch (error) {
    //   throw error
    // }
  }
}
