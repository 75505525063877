import { Component, Prop, Vue } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'

import { Icon, IconName } from '@component/Icon'
import { InputGroup } from '@component/InputGroup'
import { Input } from '@component/Input'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Search',
  components: { Input, InputGroup, Icon },
  template: `
    <InputGroup>
        <Input :model="value" :disabled="disabled" name="search" :placeholder="$t('_.filter')" @input="onValueChange($event.target.value)" />
        <template v-slot:append>
          <Icon :name="iconName" class="mr-4 text-muted" />
        </template>
    </InputGroup>
  `,
})
export class Search extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Number, required: false, default: 2 })
  public readonly minSearchLength!: number

  @Prop({ type: String, required: false, default: '' })
  public readonly query!: string

  public readonly iconName = IconName.Filter
  public value: string = ''

  private debouncer!: DebouncedFunc<(model: string) => void>

  created (): void {
    this.debouncer = debounce((value: string) => {
      this.$emit('applyFiltering', value)
    }, 500)
  }

  mounted (): void {
    if (this.query) {
      this.value = this.query
    }
  }

  public onValueChange (value: string): void {
    this.value = value

    if (!value || value.length <= this.minSearchLength) {
      this.debouncer.cancel()
      this.$emit('cleanFiltering')
      return
    }

    this.debouncer.cancel()
    this.debouncer(value)
  }
}
