// Copyright © 2021 Move Closer

import { IUser, Token } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface IExternalTokenService {
  getToken (): Token | undefined
  hasToken (): boolean
  getUser (): IUser | undefined
  setStored (payload: { tokenExpires: number; token: string }): void
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface StoredUser {
  tokenExpires: number
  token: string
  profile: IUser
  [key: string]: unknown
}

export const ExternalTokenServiceType = Symbol.for('IExternalTokenService')
