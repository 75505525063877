// Copyright © 2021 Move Closer

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface IProcessService {
  checkProcess (processId: string): Promise<boolean>
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface ProcessData {
  id: string
  status: ProcessStatus
  data: ProcessInfo | string[] | null
  related: unknown[] | null
  message?: string
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface ProcessInfo {
  type: ProcessInfoType
  message: string
  resultCode: number
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum ProcessInfoType {
  Success = 'ok',
  Error = 'error'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum ProcessStatus {
  Canceled = 'canceled',
  Finished = 'finished',
  Failed = 'failed',
  New = 'new',
  Pending = 'pending',
  Succeed = 'succeed'
}

export const ProcessServiceType = Symbol.for('IProcessService')
