// Copyright © 2021 Move Closer

import { AxiosDriver } from '@movecloser/front-core/lib/services/http/axios-driver'
import { IHttpConnectorConfig } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const http: IHttpConnectorConfig = {
  drivers: {
    radio357: () => {
      const defaultLocale = process.env.VUE_APP_HTTP_RADIO357_API_LOCALE || 'pl-PL'

      return new AxiosDriver({
        baseURL: process.env.VUE_APP_HTTP_RADIO357_API_BASEURL,
        headers: {
          Accept: 'application/json',
          'Accept-Language': defaultLocale,
          'X-Client': process.env.VUE_APP_HTTP_RADIO357_API_CLIENT,
        },
      }, process.env.VUE_APP_DEBUG === 'true')
    },
  },
  default: 'radio357',
}
