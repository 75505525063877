import { Component, Prop } from 'vue-property-decorator'

import { AbstractControl, Model } from '@component/_abstract/index'
import { getSizeClass } from '@component/_composables'

import { formControlsSizeRegistry } from './AbstractInput.config'

@Component
export class AbstractInput extends AbstractControl {
  @Prop({ type: String, required: false })
  public readonly autocomplete?: string

  @Prop({ type: String, required: false })
  public readonly placeholder?: string

  public get sizeClass (): string {
    return getSizeClass(this.size, formControlsSizeRegistry)
  }

  public get _model (): Model {
    return this.model
  }

  public set _model (value: Model) {
    this.$emit('update:model', value)
  }
}
