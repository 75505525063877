import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  Theme, getSizeClass,
  getThemeClass, Size, SizeMap,
} from '../_composables'

import { spinnerThemeClassRegistry, spinnerSizeRegistry } from './Spinner.config'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Spinner',
  template: `
    <div :class="[themeClass, spinnerClass]">
        <span class="d-none">Loading...</span>
    </div>
  `,
})
export class Spinner extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  private readonly isGrowing!: boolean

  @Prop({ type: String, required: false, default: Theme.Default })
  private readonly theme!: Theme

  @Prop({ type: String, required: false, default: SizeMap.Medium })
  private readonly size!: Size

  public get spinnerClass (): string {
    if (!this.sizeClass) {
      return this.spinnerType
    }
    return `${this.spinnerType} ${this.spinnerType}-${this.sizeClass}`
  }

  public get themeClass (): string {
    return getThemeClass<Theme>(this.theme, spinnerThemeClassRegistry)
  }

  private get spinnerType (): string {
    return this.isGrowing ? 'spinner-grow' : 'spinner-border'
  }

  private get sizeClass (): string {
    return getSizeClass(this.size, spinnerSizeRegistry)
  }
}
