// Copyright © 2021 Move Closer

import { ProcessData } from '@service/process-service'

import { ChartData, ChartInfo, IRadioUser, TrackData } from './models'

export const ChartRepositoryType = Symbol.for('IChartRepository')
export const RadioUserRepositoryType = Symbol.for('IRadioUserRepository')

/**
 * @author olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export interface IChartRepository {
  getChart (chartNo: string, slug: string, token: string): Promise<ChartData | null>
  getUserVotes (chartId: number): Promise<Array<TrackData> | null>
  load (slug: string): Promise<ChartInfo | null>
  loadLast (slug: string): Promise<ChartData | null>
  validate (payload: Array<TrackData['id']>, chartId: number): Promise<ProcessData>
  vote (payload: Array<TrackData['id']>, chartId: number): Promise<ProcessData>
}

export interface IRadioUserRepository {
  check (token: string): Promise<IRadioUser>
}

export interface IErrorRepository {
  status: string
  message: string
}

export interface ISnackbarErrorRepository {
  type: string
  message: string
  resultCode: string
}
