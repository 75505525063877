// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const voteTrackAdapterMap: MappingConfig = {
  artist: 'artist',
  artistSortName: 'artist_sort_name',
  id: 'id',
  chartAppearances: 'chart_appearances',
  mp3Url: 'mp3_url',
  name: 'name',
}
