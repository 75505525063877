/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum AbstractTheme {
  Default = 'default'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum Theme {
  Default = 'default',
  Danger = 'danger',
  Dark = 'dark',
  Info = 'info',
  Light = 'light',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type ThemeRegistry<Theme extends string|number|symbol = AbstractTheme> = Record<Theme, string>
