import { AnyObject } from '@movecloser/front-core'

export async function performTokenRefresh (refreshToken: string): Promise<{ accessToken: string; refreshToken: string }> {
  try {
    const response: AnyObject = await fetch(
      'https://auth.r357.eu/api/auth/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      },
    )

    return response.json()
  } catch (error) {
    error.name = 'Token refresh failed'
    console.error({ error })
    throw new Error(...error)
  }
}
