import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VSelect from 'vue-select'
import { ArchiveLabels } from '@module/voting/contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Select',
  components: { VSelect },
  template: `
    <v-select class="select" v-model="selected" :options="value" :searchable="searchable" />
  `,
})
export class Select extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Array, required: true })
  public value: ArchiveLabels[] | undefined

  @Prop({ type: Object, required: true })
  public currentArchive: ArchiveLabels | undefined

  @Prop({ type: Boolean, required: false, default: false })
  public searchable: boolean | undefined

  public selected: ArchiveLabels | undefined = { label: null, value: null }

  @Watch('selected')
  redirectToArchive (): void {
    if (this.selected !== this.currentArchive) {
      const slug = this.selected!.value![0]
      const no = this.selected!.value![1]
      this.$router.push({ name: 'voting.results', params: { slug: slug, no: no } })
    }
  }

  @Watch('$route.params.no')
  reloadPage (): void {
    location.reload()
  }

  created (): void {
    this.selected = this.currentArchive
  }
}
