import { Component, Vue, Prop } from 'vue-property-decorator'

import { getSizeClass, Size, SizeMap } from '../_composables'

import { IconName, iconSizeRegistry } from './Icon.config'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Icon',
  template: `
    <i  class="icon" :class="[name, sizeClass]" />
  `,
})
export class Icon extends Vue {
  @Prop({ type: String, required: true })
  private readonly name!: IconName | string

  @Prop({ type: String, required: false, default: SizeMap.Medium })
  private readonly size!: Size

  public get sizeClass (): string {
    return getSizeClass(this.size, iconSizeRegistry)
  }
}
