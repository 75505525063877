// Copyright © 2021 Move Closer

import {
  AuthMiddlewareType,
  ValidationMiddlewareType,
} from '@movecloser/front-core'

import { IdentityMiddlewareType } from '@service/identity'
import { UnauthorizedMiddlewareType } from '@service/unauthorize'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const middlewares: symbol[] = [
  // Here you can register global middleware that will be use
  // each API request.
  AuthMiddlewareType,
  IdentityMiddlewareType,
  UnauthorizedMiddlewareType,
  ValidationMiddlewareType,
]
