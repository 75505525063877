// Copyright © 2021 Move Closer
import Cookies from 'js-cookie'

import { Injectable, IUser, Token } from '@movecloser/front-core'

import { IExternalTokenService, StoredUser } from './contracts'

/**
 * Service to get user token from localStorage
 */
@Injectable()
export class ExternalTokenService implements IExternalTokenService {
  private readonly storageKey: string
  private readonly expiresKey: string
  private stored: null | StoredUser

  public constructor (storageTokenKey: string = 'token', expiresKey: string = 'token_expires') {
    this.storageKey = storageTokenKey
    this.expiresKey = expiresKey
    this.stored = null
  }

  public setStored (payload: { tokenExpires: number; token: string }): void {
    this.stored = {
      profile: this.stored?.profile ?? {} as IUser,
      tokenExpires: payload.tokenExpires,
      token: payload.token,
    }
  }

  public getToken (): Token | undefined {
    if (!this.stored) {
      return
    }
    return {
      accessToken: this.stored.token,
      expiresAt: new Date(this.stored.tokenExpires).toDateString(),
      tokenType: 'Bearer',
    }
  }

  public getUser (): IUser | undefined {
    if (!this.hasToken() || !this.stored) {
      return
    }

    return this.stored.profile
  }

  public hasToken (): boolean {
    const token = Cookies.get(this.storageKey)
    const expires = Cookies.get(this.expiresKey)

    if (!token || !expires) {
      return false
    }

    const stored = {
      token,
      tokenExpires: Number(expires),
    }

    // if (Number(expires) < Date.now()) {
    //   return false
    // }

    this.storeUser(stored)
    return true
  }

  private storeUser (user: unknown): void {
    if (!user) {
      return
    }

    this.stored = user as StoredUser
  }
}
