// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

import { Button } from '@component/Button'
import { Inject } from '@plugin/inversify'
import { Link } from '@component/Link'
import { ChartInfo } from '@module/voting/contracts'

/**
 * Heading for voting view
 *
 * @author olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'VotingHeader',
  template: `
    <div>
    <div class="px-5 px-lg-0 pt-5 pt-lg-0">
      <template v-if="chart && !chart.id">
        <div class="text-center text-lg-left pt-4 px-4 pt-lg-0 px-lg-0">
          <span class="d-block fs-large">
            {{ $t('vote.no-chart') }}
          </span>
        </div>
      </template>

      <template v-else-if="isVotingCompleted || alreadyVoted">
        <div class="text-center text-lg-left pt-4 px-4 pt-lg-0 px-lg-0">
            <span class="d-block fs-large">
              {{ headingInfo }}
            </span>

          <span v-if="isVotingCompleted" class="d-block fs-small font-weight-light">
              {{ $t('vote.counting') }}
            </span>

          <span class="font-weight-light">
            {{ $t('vote.resultPublication', { date: dateTime.parseToFormat(chart.announcementAt, 'DD.MM.YYYY, HH:mm') }) }}
          </span>
        </div>
      </template>

      <template v-else>
        <div class="container p-xl-0">
          <div class="row">
              <span v-if="!isUserLogin" class="col-12 col-lg-8 mb-3 mb-lg-0 fs-large">
                {{ $t('vote.only-login') }}
              </span>
            <span v-if="isUserLogin && !isPatron" class="col-12 col-lg-8 mb-3 mb-lg-0 fs-large">
                {{ $t('vote.user-info') }}
              </span>
            <Link v-if="!isUserLogin" :target="fullPathLink"
                  class="col-12 col-lg-4 d-flex align-items-center justify-content-start justify-content-lg-end text-decoration-none">
              <Button>{{ $t('_.login') }}</Button>
            </Link>
            <Link v-if="isUserLogin && !isPatron" :target="accountLink"
                  class="col-12 col-lg-4 d-flex align-items-center justify-content-start justify-content-lg-end text-decoration-none">
              <Button>{{ $t('_.account') }}</Button>
            </Link>
          </div>
        </div>
      </template>
    </div>
    </div>
  `,
  components: {
    Button,
    Link,
  },
})
export class VotingHeader extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly alreadyVoted!: boolean

  @Prop({
    type: Object,
    required: false,
  })
  public readonly chart!: ChartInfo | null

  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly confirmation!: boolean

  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly isUserLogin!: boolean

  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly isPatron!: boolean

  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly isVotingCompleted!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly pending!: string

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  public readonly loginLink = process.env.VUE_APP_RADIO357_LOGIN_LINK
  public readonly accountLink = process.env.VUE_APP_RADIO357_ACCOUNT_LINK

  public get headingInfo (): string {
    if (this.isVotingCompleted) {
      return `${this.$t('vote.complete')}`
    } else if (this.pending) {
      return `${this.$t('vote.pending')}`
    }

    return this.confirmation ? `${this.$t('vote.confirmation')}` : `${this.$t('vote.alreadyVoted')}`
  }

  public get fullPathLink (): string {
    return this.loginLink + window.location.origin + '/app/' + this.$route.params.slug + '/glosowanie'
  }
}
