// Copyright © 2021 Move Closer

import { merge } from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import atomsPL from '@/assets/langs/pl/atoms.json'
import commonPl from '@/assets/langs/pl/common.json'

Vue.use(VueI18n)

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  silentTranslationWarn: true,
  messages: {
    pl: {
      ...merge(atomsPL, commonPl),
    },
  },
})
