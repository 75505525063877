import { Component, Vue, Prop } from 'vue-property-decorator'

import { getSizeClass, Size, SizeMap } from '../_composables'

import { inputGroupSizeRegistry } from './InputGroup.config'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'InputGroup',
  template: `
    <div class="input-group custom" :class="sizeClass">

    <div v-if="this.$slots.prependText" class="input-group-prepend align-items-center">
        <span class="input-group-text">
          <slot name="prependText" />
        </span>
    </div>

    <div v-if="this.$slots.prepend" class="input-group-prepend">
      <slot name="prepend" />
    </div>

    <slot />

    <div v-if="this.$slots.appendText" class="input-group-append">
        <span class="input-group-text">
          <slot name="appendText" />
        </span>
    </div>

    <div v-if="this.$slots.append" class="input-group-append align-items-center">
      <slot name="append" />
    </div>

    </div>
  `,
})
export class InputGroup extends Vue {
  @Prop({ type: String, required: false, default: SizeMap.Medium })
  private readonly size!: Size

  public get sizeClass (): string {
    return getSizeClass(this.size, inputGroupSizeRegistry)
  }
}
