
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ConnectorErrors } from '@/shared/exceptions/connector-errors'
import { FullScreenLoader } from '@component/FullScreenLoader'
import { Header } from '@component/Header'
import { Inject } from '@plugin/inversify'
import { Theme } from '@component/_composables'

import {
  ChartRepositoryType,
  IChartRepository,
  IErrorRepository,
} from '../contracts/repositories'
import { Archive, ChartInfo } from '../contracts'

import { ChartView } from './ChartView.vue'
import { ContentView } from './ContentView.vue'
import { InfoView } from './InfoView.vue'
import { VotingError } from '../components/VotingError.vue'
import { VotingView } from './VotingView.vue'
import { ExternalTokenServiceType, IExternalTokenService } from '@module/root/services/external-token-service'

/**
 * Main View for Voting/Chart module
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<MainView>({
  name: 'MainView',
  components: { Header, FullScreenLoader, VotingError, VotingView, ChartView, InfoView, ContentView },
  mounted (): void {
    if (this.$route.params.no) {
      this.getChartById(this.$route.params.no, this.$route.params.slug)
    } else if (this.$route.name === 'voting.lastResults') {
      this.getLastResult(this.$route.params.slug)
    } else {
      this.getCurrentChart(this.$route.params.slug)
    }
  },
})
export class MainView extends Vue {
  @Inject(ChartRepositoryType)
  private chartRepository!: IChartRepository

  @Inject(ExternalTokenServiceType)
  private externalTokenService!: IExternalTokenService

  @Prop({ type: Boolean, required: true })
  public readonly isPatron!: boolean

  public archives: Archive = {}
  public chart: ChartInfo | null = null
  public error: IErrorRepository | null = null
  public isInfoShow = false
  public loading: boolean = true
  public readonly theme = Theme

  private token: string = ''

  public get errorMessage (): string | undefined {
    if (!this.error || !this.error.hasOwnProperty('status')) {
      return
    }

    return (this.error.status === ConnectorErrors.ServerError || this.error.status === ConnectorErrors.Unknown)
      ? `${this.$t('vote.error.text')}` : this.error.message
  }

  public get votingNo (): number {
    if (!this.chart || !this.chart.id) {
      return 0
    }

    return this.chart.no
  }

  /**
   * Event handler for infoview event.
   */
  public onInfoAction (): void {
    this.isInfoShow = false

    window.scrollTo(0, 0)
  }

  private updateDOM (chart: ChartInfo | null) {
    if (!chart) {
      console.error('DOM Update Failed')
      return
    }

    const body = document.body

    const slug = this.$route.params.slug

    body.id = `type-${slug}`
    body.dataset.chartType = `${slug}`
    body.dataset.chartId = chart.id ? `${chart.id}` : 'unknown-chartId'
    body.dataset.chartNo = chart.no ? `${chart.no}` : 'unkown-chartNo'
  }

  private getCurrentChart (slug: string) {
    this.loading = true
    this.chartRepository.load(slug).then(chart => {
      this.chart = chart

      this.updateDOM(chart)

      if (this.error) {
        this.error = null
      }
    }).catch(e => {
      this.error = e
      console.debug(e)
    }).finally(() => {
      this.loading = false
    })
  }

  private getChartById (no: string, slug: string) {
    if (this.externalTokenService?.hasToken()) {
      const token = this.externalTokenService.getToken()?.accessToken
      this.token = token?.length ? token : ''
    }
    this.chartRepository.getChart(no, slug, this.token).then(result => {
      if (result === null) {
        return
      }
      this.chart = result.chart
      this.archives = result.results
    }).catch(e => {
      this.error = e
      console.warn(e)
    })
      .finally(() => {
        this.loading = false
      })
  }

  private getLastResult (slug: string) {
    this.loading = true
    this.chartRepository.loadLast(slug).then(result => {
      this.chart = result!.chart
      this.archives = result!.results

      if (this.error) {
        this.error = null
      }
    }).catch(e => {
      this.error = e
      console.debug(e)
    }).finally(() => {
      this.loading = false
    })
  }
}

export default MainView
