import { SizeMap, SizeRegistry } from '../_composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum IconName {
  ArrowLeft = 'fas fa-arrow-left',
  Check = 'fas fa-check',
  ChevronDown = 'fas fa-chevron-down',
  ChevronUp = 'fas fa-chevron-up',
  Filter = 'fas fa-search',
  Image = 'far fa-image',
  X = 'far fa-times-circle'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const iconSizeRegistry: SizeRegistry = {
  [SizeMap.Large]: '--large',
  [SizeMap.Medium]: '',
  [SizeMap.Small]: '--small',
  [SizeMap.XLarge]: '--x-large',
  [SizeMap.XSmall]: '--x-small',
}
