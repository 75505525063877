import { VueConstructor } from 'vue'

import { focus } from './extensions/focus'

/**
 * Register all Vue extensions.
 *
 * @author Łukasz Sitnicki <lukasz.sitnici@movecloser.pl>
 * @version 1.0.0
 */
export function registerExtensions (Vue: VueConstructor): void {
  const extensions = {
    directives: {
      focus,
    },
  }

  // Register all custom directives
  for (const [name, handler] of Object.entries(extensions.directives)) {
    Vue.directive(name, handler)
  }
}
