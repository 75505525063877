import { Component, Prop, Vue } from 'vue-property-decorator'

import { BackgroundTheme, backgroundThemeClassRegistry } from '../_composables/background'
import { getThemeClass } from '../_composables'
import { Spinner } from '../Spinner'

import { ListItem } from './List.contract'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'List',
  components: { Spinner },
  template: `
    <div class="list">
      <div v-if="loading" class="list__loader">
        <Spinner/>
      </div>

      <ul v-else class="list-group list-group-flush"
          :class="[backgroundClass,
            {'--minimalist': minimalist },
            {'--horizontal': horizontal },
            {'--with-bottom': withBorder }]"
      >
        <template v-for="(listItem, idx) in list">
            <component :is="listItem.isAction ? 'button' : 'li'"
                       class="list-group-item"
                       :data-vote-title="listItem.data.name"
                       :data-vote-artist="listItem.data.artist"
                       :data-vote-id="listItem.data.id"
                       :key="listItem.data.id">
              <slot v-bind="listItem"/>
            </component>
        </template>
      </ul>
    </div>`,
})
export class List extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly horizontal!: boolean

  @Prop({ type: Array, required: true })
  public readonly list!: Array<ListItem>

  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly minimalist!: boolean

  @Prop({ type: String, required: false, default: BackgroundTheme.White })
  public readonly theme!: BackgroundTheme

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withBorder!: boolean

  public get backgroundClass (): string {
    return getThemeClass<BackgroundTheme>(this.theme, backgroundThemeClassRegistry)
  }
}
