// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const resources: ResourcesRegistry = {
  auth: {
    prefix: 'api',
    methods: {
      check: {
        url: 'me',
        method: Methods.Get,
        auth: true,
      },
    },
  },
}
