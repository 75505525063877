import { Component, Prop, Vue } from 'vue-property-decorator'

import { Size, SizeMap } from '../_composables'

import { Model } from './AbstractControl.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component
export class AbstractControl extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly autofocus!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasErrors!: boolean

  @Prop({ type: [String, Number, Array, Boolean], required: true })
  public readonly model!: Model

  @Prop({ type: String, required: true })
  public readonly name!: string

  @Prop({ type: Boolean, required: false })
  public readonly readonly?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly required?: boolean

  @Prop({ type: String, required: false, default: SizeMap.Medium })
  public readonly size!: Size

  @Prop({ type: Boolean, required: false, default: undefined })
  public readonly valid?: boolean

  public get isValid (): boolean | undefined {
    if (this.hasErrors) {
      return false
    }
    return this.valid
  }

  public get validationClass (): string {
    if (typeof this.valid === 'undefined' && !this.hasErrors) {
      return ''
    }
    return this.isValid ? 'is-valid' : 'is-invalid'
  }
}
