import { ThemeRegistry } from '@component/_composables'

import { BackgroundOpacity, BackgroundTheme } from './background.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const backgroundThemeClassRegistry: ThemeRegistry<BackgroundTheme> = {
  [BackgroundTheme.Danger]: 'bg-danger text-white',
  [BackgroundTheme.Dark]: 'bg-dark text-white',
  [BackgroundTheme.Info]: 'bg-info text-white',
  [BackgroundTheme.Light]: 'bg-light text-body',
  [BackgroundTheme.Primary]: 'bg-primary text-white',
  [BackgroundTheme.Success]: 'bg-success text-white',
  [BackgroundTheme.Secondary]: 'bg-secondary text-body',
  [BackgroundTheme.Warning]: 'bg-warning text-white',
  [BackgroundTheme.Black]: 'bg-black text-white',
  [BackgroundTheme.White]: 'bg-white text-body',
  [BackgroundTheme.Transparent]: 'bg-transparent',
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const backgroundOpacityClassRegistry: ThemeRegistry<BackgroundOpacity> = {
  [BackgroundOpacity['10%']]: 'bg-opacity-10',
  [BackgroundOpacity['25%']]: 'bg-opacity-25',
  [BackgroundOpacity['50%']]: 'bg-opacity-50',
  [BackgroundOpacity['75%']]: 'bg-opacity-75',
}
