import { Theme, ThemeRegistry } from '../_composables'

import { BadgeShape } from './Badge.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const badgeThemeClassRegistry: ThemeRegistry<Theme> = {
  [Theme.Default]: 'badge-primary',
  [Theme.Danger]: 'badge-danger',
  [Theme.Dark]: 'badge-dark',
  [Theme.Info]: 'badge-info',
  [Theme.Light]: 'badge-light',
  [Theme.Primary]: 'badge-primary',
  [Theme.Success]: 'badge-success',
  [Theme.Warning]: 'badge-warning',
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const badgeShapeRegistry: ThemeRegistry<BadgeShape> = {
  [BadgeShape.Pill]: 'badge-pill',
  [BadgeShape.Rectangle]: '',
}
