// Copyright © 2021 Move Closer

import { AppConfig, RouterDriver, StoreDriver } from '@movecloser/front-core'

import { app } from './app'
import { auth } from './auth'
import { http } from './http'
import { middlewares } from './middlewares'
import { modules } from './modules'
import { resources } from './resources'
import { services } from './services'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const config: AppConfig = {
  // This is global config index. Use it to register any properties
  // that should be available inside application.
  // To access any of them use: Configuration
  app,
  auth,
  modules,
  resources,
  middlewares,
  http,
  services,
  router: RouterDriver.VueRouter,
  store: StoreDriver.None,
}
