// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author olga Milczek <olga.milczek@movecloser.pl>
 */
export const trackAdapterMap: MappingConfig = {
  artist: 'artist',
  id: 'id',
  isNew: 'is_new',
  lastPosition: 'last_position',
  name: 'name',
  position: 'position',
  timesOnChart: 'times_on_chart',
}
