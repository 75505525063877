/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */

export enum BackgroundTheme {
  Danger = 'danger',
  Dark = 'dark',
  Info = 'info',
  Light = 'light',
  Primary = 'primary',
  Success = 'success',
  Secondary = 'secondary',
  Warning = 'warning',
  Black = 'black',
  White = 'white',
  Transparent = 'transparent'
}

export enum BackgroundOpacity {
  '10%' = 10,
  '25%' = 25,
  '50%' = 50,
  '75%' = 75,
}
