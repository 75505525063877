
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { ChartData, IRadioUserRepository, RadioUserRepositoryType } from '@module/voting/contracts'
import { VotingView } from './VotingView.vue'
import { ExternalTokenServiceType, IExternalTokenService } from '@module/root/services/external-token-service'

/**
 * Content View handle Voting/Chart modules for routing
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<ContentView>({
  name: 'ContentView',
  components: { VotingView },
})
export class ContentView extends Vue {
  @Inject(ExternalTokenServiceType)
  private externalTokenService!: IExternalTokenService

  @Inject(RadioUserRepositoryType)
  private patronRepository!: IRadioUserRepository

  @Prop({ type: Object, required: false })
  public readonly chart!: ChartData | null

  @Prop({ type: Boolean, required: true })
  public readonly isInfoShow!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly isPatron!: boolean
}
export default ContentView
