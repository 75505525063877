
import { Authentication, AuthEvent, AuthServiceType, DateTimeType, IDateTime, IUser } from '@movecloser/front-core'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { BackgroundTheme } from '@component/_composables'
import { Button } from '@component/Button'
import { Inject } from '@plugin/inversify'
import { IProcessService, ProcessServiceType } from '@service/process-service'
import { Layout } from '@component/Layout'
import { Link } from '@component/Link'
import { Picture } from '@component/Picture'
import { SnackBar } from '@component/SnackBar'

import {
  ChartRepositoryType,
  ChartInfo, TrackData,
  IChartRepository,
  IRadioUserRepository, ISnackbarErrorRepository,
  RadioUserRepositoryType,
} from '../contracts'
import { Vote } from '../components/Vote'
import { VotingHeader } from '../components/VotingHeader'
import { VotingList } from '../components/VotingList'

/**
 * View displaying voting list
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<VotingView>({
  name: 'VotingView',
  components: {
    Layout,
    VotingList,
    Vote,
    Picture,
    Button,
    Link,
    SnackBar,
    VotingHeader,
  },

  created (): void {
    if (!this.chart || !this.chart.id) {
      return
    }

    this.isVotingCompleted = this.dateTime.difference(this.chart.endsAt) <= 0

    this.authService.listen((event: AuthEvent) => {
      if (event.type === 'invalidated') {
        if (this.votes.length > 0) {
          // When user is voting and his session has ended
          // Logout user after timeout
          setTimeout(() => {
            this.isUserLogin = false
          }, 3000)

          return
        }
        this.isUserLogin = false
      } else if (event.type === 'authenticated') {
        this.isUserLogin = true
      }
    })
  },
})
export class VotingView extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public readonly chart!: ChartInfo | null

  @Prop({
    type: Boolean,
    required: true,
  })
  public isPatron!: boolean

  @Inject(AuthServiceType)
  private readonly authService!: Authentication<IUser>

  @Inject(ChartRepositoryType)
  private readonly chartRepository!: IChartRepository

  @Inject(RadioUserRepositoryType)
  private readonly patronRepository!: IRadioUserRepository

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(ProcessServiceType)
  private readonly processService!: IProcessService

  public alreadyVoted: boolean = false
  public confirmation: boolean = false
  public error: ISnackbarErrorRepository | null = null
  public isUserLogin: boolean = false
  public isVotingCompleted: boolean = false
  public loading = false
  public pending = false
  public votes: Array<TrackData['id']> = []
  public tracks: TrackData[] = []

  // eslint-disable-next-line no-undef
  private updateWhenEnds?: NodeJS.Timeout

  public get canUserVote (): boolean {
    if (this.isVotingCompleted) {
      return false
    }

    if (!(this.isPatron)) {
      return false
    }
    return !(!this.isUserLogin || this.alreadyVoted)
  }

  public get hasHeader (): boolean {
    return this.isVotingCompleted || this.alreadyVoted || !this.isUserLogin || !this.isPatron
  }

  public get img (): string {
    if (this.alreadyVoted) {
      return require('../../../assets/images/photos/hamak/hamak.png')
    } else if (this.confirmation) {
      return require('../../../assets/images/photos/concert/concert.png')
    }

    return require('../../../assets/images/photos/pasiak/pasiak.png')
  }

  public get list (): TrackData[] {
    if (!this.chart || !this.chart.id) {
      return []
    }

    if ((this.confirmation && this.canUserVote) || this.isVotingCompleted) {
      return this.tracks
    }
    return this.chart.tracks
  }

  public get listTheme (): BackgroundTheme {
    if ((this.isUserLogin && this.canUserVote && !this.confirmation) || this.alreadyVoted) {
      return BackgroundTheme.Light
    }

    return BackgroundTheme.White
  }

  public onChange (): void {
    this.confirmation = false
  }

  public async onVote (): Promise<void> {
    if (!this.chart || !this.chart.id || !Array.isArray(this.tracks) || !this.tracks.length) {
      return
    }

    if (!this.confirmation) {
      await this.validate()
    } else {
      await this.vote()
    }
  }

  protected async validate (): Promise<void> {
    try {
      const status = await this.chartRepository.validate(this.votes, this.chart!.id)

      this.clearErrors()
      try {
        if (status.message && status.message === 'OK') {
          this.confirmation = true

          this.clearErrors()
        }
      } catch (error) {
        this.error = error
      } finally {
        this.pending = false
      }
    } catch (e) {
      this.error = e
    }

    this.pending = false
  }

  protected async vote (): Promise<void> {
    this.pending = true

    try {
      const processStatus = await this.chartRepository.vote(this.votes, this.chart!.id)

      this.clearErrors()

      this.alreadyVoted = true
      const value = await this.processService.checkProcess(processStatus.id)

      if (value) {
        this.pending = false
        this.clearErrors()
      }
      // @TODO
      // Add else for value if another status message for pending is needed
    } catch (e) {
      this.error = e
      this.alreadyVoted = false
      this.pending = false
    }
  }

  @Watch('isUserLogin')
  private loadUserVotes (isUserLogin: boolean): void {
    if (!isUserLogin) {
      return
    }

    if (!this.chart || !this.chart.id) {
      return
    }

    this.loading = true
    this.chartRepository.getUserVotes(this.chart.id).then(result => {
      if (!result) {
        return
      }
      this.tracks = result
      if (result) {
        this.alreadyVoted = true
      } else {
        this.alreadyVoted = false
      }
    }).catch(e => {
      if (this.isVotingCompleted && this.confirmation) {
        this.error = e
      }
      console.warn(e)
    })
      .finally(() => {
        this.loading = false
      })
  }

  @Watch('canUserVote')
  private handleUserCanVote (canUserVote: boolean) {
    const body = document.querySelector('#app')
    if (canUserVote && window.innerWidth <= 768) {
      // eslint-disable-next-line no-unused-expressions
      body?.classList.add('app__vote--mobile')
    } else {
      // eslint-disable-next-line no-unused-expressions
      body?.classList.remove('app__vote--mobile')
    }
  }

  @Watch('confirmation', { immediate: true })
  onConfirmationChange (confirmation: boolean): void {
    const step = confirmation ? 'potwierdzenie' : ''
    this.updateStep(step)
  }

  @Watch('alreadyVoted', { immediate: true })
  onAlreadyVotedChange (alreadyVoted: boolean): void {
    const step = alreadyVoted ? 'dziękujemy' : ''
    this.updateStep(step)
  }

  private updateStep (step: string): void {
    // Avoid redirecting to the same step
    if (step === this.$route.query.step) return
    // Avoid duplicated navigation to empty
    if (!step && !this.$route.query.step) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: step ? { step } : {},
    })
  }

  private clearErrors () {
    if (this.error) {
      this.error = null
    }
  }
}

export default VotingView
