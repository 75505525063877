/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type Size = SizeMap.Large | SizeMap.Medium | SizeMap.Small | SizeMap.XLarge | SizeMap.XSmall

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum SizeMap {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XLarge = 'x-large',
  XSmall = 'x-small'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type SizeRegistry = Record<SizeMap, string>
