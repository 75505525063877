// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Button } from '@component/Button'
import { Card } from '@component/Card'
import { IconName } from '@component/Icon'
import { Theme } from '@component/_composables'
import { Spinner } from '@component/Spinner'

/**
 * Component to display button for voting
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Vote',
  components: {
    Button,
    Card,
    Spinner,
  },
  template: `
    <Card class="vote">
    <div class="vote__content" :class="{'--confirmation' : confirmation }">
      <!-- Votes on desktop -->
      <div class="d-none d-lg-flex flex-column">
        <span class="vote__text">{{ confirmation ? $t('vote.yours-votes') : $t('vote.votes-cast') }}</span>

        <span class="vote__votes">{{ numberOfVotes + '/' + maxVotes }}</span>
      </div>

      <template>
        <!-- Votes on mobile -->
        <div class="d-lg-none" :class="{'d-none' : confirmation }">
          <span>{{ $t('vote.votes') }}{{ numberOfVotes + ' z ' + maxVotes }}</span>
        </div>

        <!-- Vote change Button - for mobile devices -->
        <Button v-if="confirmation && !!numberOfVotes"
                :disabled="pending"
                class="d-lg-none" variant="icon"
                :label="$t('vote.change')" :icon="{ name: iconName }" @click="$emit('change')"/>

        <Button class="mb-lg-4"
                :theme="!!numberOfVotes ? theme.Primary : theme.Dark"
                :loading="pending"
                :disabled="numberOfVotes < minVotes || numberOfVotes > maxVotes"
                :label="confirmation ? $t('vote.confirm'): $t('vote.vote')"
                @click="$emit('vote')"/>

        <!-- Vote change Button - for desktop -->
        <Button v-if="confirmation && !!numberOfVotes"
                :disabled="pending"
                class="d-none d-lg-block" :theme="theme.Primary" variant="outline"
                :label="$t('vote.change')" @click="$emit('change')"/>
      </template>
    </div>
    </Card>
  `,
})
export class Vote extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly confirmation!: boolean

  @Prop({
    type: Number,
    required: true,
  })
  public readonly maxVotes!: number

  @Prop({
    type: Number,
    required: true,
  })
  public readonly minVotes!: number

  @Prop({
    type: Number,
    required: true,
  })
  public readonly numberOfVotes!: number

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly pending!: boolean

  public readonly iconName = IconName.ArrowLeft
  public readonly theme = Theme
}
