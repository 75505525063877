// Copyright © 2021 Move Closer

/**
 * Types of info to display in Chart info component.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum InfoTypes {
  AnnouncementAt = 'announcementAt',
  EndedAt = 'endsAt',
  MaxVotes = 'maxVotesPerUser',
  Name = 'name',
  No = 'no',
  StartAt = 'startsAt'
}
