// Copyright © 2021 Move Closer

import { SummaryTypes } from './ChartSummary.contracts'

/**
 * Default object to collect chart summary
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const summaryConfig: Record<SummaryTypes, number> = {
  // Order by display order
  [SummaryTypes.NewItems]: 0,
  [SummaryTypes.Up]: 0,
  [SummaryTypes.Down]: 0,
  [SummaryTypes.NoChange]: 0,
  [SummaryTypes.LongestOnChart]: 0,
}
