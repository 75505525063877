// Copyright © 2021 Move Closer

import { RoutesFactory } from '@movecloser/front-core'
import ContentView from '@module/voting/views/ContentView.vue'
// import VotingResultView from '@module/voting/views/VotingResultView.vue'
import { Error } from '@component/Error'
import { Route } from 'vue-router/types/router'

const composeRedirectUrl = (to: Route) => {
  let base = process.env.VUE_APP_RADIO357_RESULTS_LINK
  const { no, slug } = to.params

  if (slug) {
    base = `${base}/${slug}`
  }

  if (no) {
    base = `${base}/${no}`
  }

  return `${base}`
}

export const routesFactory: RoutesFactory = () => {
  return [
    {
      // component: VotingResultView,
      path: '/:slug/wyniki/:no',
      name: 'results',
      props: true,
      beforeEnter (to: Route) {
        window.location.href = composeRedirectUrl(to)
      },
    },
    {
      // component: VotingResultView,
      path: '/:slug/wyniki',
      name: 'lastResults',
      props: true,
      beforeEnter (to: Route) {
        window.location.href = composeRedirectUrl(to)
      },
    },
    {
      path: '/:slug/glosowanie',
      name: 'voting',
      component: ContentView,
      props: true,
    },
    {
      path: '/',
      name: 'content',
      redirect: 'lista/glosowanie',
    },
    {
      path: '*',
      component: Error,
      props: { errorRedirect: true },
    },
  ]
}
