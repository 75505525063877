// Copyright (C) 2021 Move Closer

import { AuthConfig, TokenDriver } from '@movecloser/front-core'

export const auth: AuthConfig = {
  refreshThreshold: 0,
  tokenName: 'radio357_token',
  tokenDriver: TokenDriver.Solid,
  validThreshold: 0,
}
