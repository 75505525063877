import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  Theme,
  getThemeClass,
} from '../_composables'

import { badgeThemeClassRegistry, badgeShapeRegistry } from './Badge.config'
import { BadgeShape } from './Badge.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Badge',
  template: `
    <span class="badge" :class="[themeClass, shapeClass]">
    <slot>
      {{ label }}
    </slot>
    </span>
  `,
})
export class Badge extends Vue {
  @Prop({ type: String, required: false, default: '' })
  public readonly label!: string

  @Prop({ type: String, required: false, default: '' })
  private readonly shape!: BadgeShape

  @Prop({ type: String, required: false, default: Theme.Default })
  private readonly theme!: Theme

  public get shapeClass (): string {
    return getThemeClass<BadgeShape>(this.shape, badgeShapeRegistry)
  }

  public get themeClass (): string {
    return getThemeClass<Theme>(this.theme, badgeThemeClassRegistry)
  }
}
