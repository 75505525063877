// Copyright © 2021 Move Closer

/**
 * Types of summary data
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum SummaryTypes {
  NewItems = 'newItems',
  Up = 'up',
  Down = 'down',
  NoChange = 'noChange',
  LongestOnChart = 'longestOnChart'
}
