// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Badge } from '@component/Badge'
import { List, ListItem } from '@component/List'

import { TrackData } from '../../contracts'

import { SummaryTypes } from './ChartSummary.contracts'
import { summaryConfig } from './ChartSummary.config'

/**
 * Component to display chart summary
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ChartSummary',
  components: { List, Badge },
  template: `
    <div class="chart-summary">
      <List :list="summaryList" :loading="loading" horizontal minimalist>
        <template v-slot="item">
          <div class="chart-summary__item" :class="colorClass(item.data.label)">
            <span v-if="item.data.label === 'newItems'">
              <Badge>
                {{$t('chart.position.new').toUpperCase()}}
              </Badge>
            </span>

              <span v-else>{{ $t('summary.' + item.data.label) }}</span>
              <span>{{ item.data.value }}</span>
          </div>
        </template>
      </List>
    </div>
  `,
})
export class ChartSummary extends Vue {
  @Prop({ type: Array, required: true })
  public readonly list!: TrackData[]

  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  public get summaryList (): ListItem[] {
    const summary = { ...summaryConfig }

    // Counting summary values
    for (const track of this.list) {
      if (track.isNew) {
        summary.newItems += 1
        continue
      }

      if (track.timesOnChart && summary.longestOnChart < track.timesOnChart) {
        summary.longestOnChart = track.timesOnChart
      }

      if (!track.lastPosition) {
        continue
      }

      if (track.lastPosition > track.position) {
        summary.up += 1
        continue
      }

      if (track.lastPosition < track.position) {
        summary.down += 1
        continue
      }

      summary.noChange += 1
    }

    // Mapping counted summaries values to ListItem structure
    return Object.entries(summary).map(([label, value]: [string, number]) => ({ data: { label, value } }))
  }

  public colorClass (label: SummaryTypes): string {
    switch (label) {
      case SummaryTypes.Up:
        return 'text-success'
      case SummaryTypes.Down:
        return 'text-danger'
      case SummaryTypes.NewItems:
      case SummaryTypes.NoChange:
      case SummaryTypes.LongestOnChart:
        return ''
    }
  }
}
