// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { BackgroundTheme } from '@component/_composables'
import { List, ListItem } from '@component/List'

import { TrackData } from '../../contracts'

import { ChartItem } from '../ChartItem'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ChartList',
  components: { ChartItem, List },
  template: `
    <div class="chart-list">
    <div v-if="this.$slots.header" class="chart-list__header">
      <slot name="header"/>
    </div>

    <List :theme="listTheme" :list="chartList" :loading="loading" :withBorder="withBorder">
      <template v-slot="item">
        <ChartItem :minimalist="minimalist" :item="item.data" :isWaitingList="isWaitingList"/>
      </template>
    </List>
    </div>
  `,
})
export class ChartList extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isWaitingList!: boolean

  @Prop({ type: Array, required: true })
  public readonly list!: TrackData[]

  @Prop({ type: String, required: false, default: BackgroundTheme.Transparent })
  public readonly listTheme!: BackgroundTheme

  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly minimalist!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withBorder!: boolean

  public get chartList (): ListItem[] {
    return this.list.map(i => {
      return {
        data: i,
        backgroundColor: i.position < 4 && !this.minimalist ? BackgroundTheme.Secondary : undefined,
      }
    })
  }
}
