import { SizeMap, SizeRegistry } from '../_composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const inputGroupSizeRegistry: SizeRegistry = {
  [SizeMap.Large]: 'input-group-lg',
  [SizeMap.Medium]: '',
  [SizeMap.Small]: 'input-group-sm',
  [SizeMap.XLarge]: 'input-group-lg',
  [SizeMap.XSmall]: 'input-group-sm',
}
