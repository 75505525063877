// Copyright © 2021 Move Closer

import { IConnector, Injectable, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { IProcessService, ProcessData, ProcessInfoType, ProcessStatus } from './contracts'

/**
 * Service to check for process status
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class ProcessService implements IProcessService {
  private readonly connector: IConnector
  private readonly delays: number[]
  private isDelayPending: boolean = false

  public constructor (connector: IConnector, delay: number[]) {
    if (!delay.length) {
      throw new Error('[ProcessService]: Incorrect delays set. An array of sec offsets required!')
    }

    this.connector = connector
    this.delays = delay
  }

  /**
   * Method for checking process status
   */
  public checkProcess (processId: string, delays?: number[] | undefined): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.runRound(processId, delays ?? this.delays, 0, resolve, reject)
    })
  }

  /**
   * Method for call for process status form API
   */
  private async getProcess (processId: string): Promise<ProcessData> {
    const response = await this.connector.call(
      'process',
      'get',
      { processId },
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {},
      )
    }

    return response.data.data as ProcessData
  }

  /**
   * Resolves delay in micro-time.
   */
  private static resolveDelay (delays: number[], round: number): number {
    const index = delays.length < round ? delays.length - 1 : round

    return delays[index] * 1000
  }

  /**
   *
   */
  private runRound (processId: string, delays: number[], round: number, onSuccess: any, onError: any): void {
    if (round > delays.length - 1) {
      this.isDelayPending = true
    }

    setTimeout(() => {
      this.getProcess(processId).then(response => {
        switch (response.status) {
          case ProcessStatus.Canceled:
          case ProcessStatus.Failed:
            // If process failed of canceled reject promise with Error
            onError(new Error(ProcessService.getErrorMessage(response)))
            break

          case ProcessStatus.Finished:
            // If process finished check its type and proceed the required action
            if (response.data && !Array.isArray(response.data) && response.data.type === ProcessInfoType.Success) {
              onSuccess(true)
              return
            }
            onError(new Error(ProcessService.getErrorMessage(response)))
            break

          case ProcessStatus.Succeed:
            // If process succeed check resolve promise
            onSuccess(true)
            break

          case ProcessStatus.Pending:
          case ProcessStatus.New:
          default:
            // If process is new or pending do nothing and wait for next call
            this.runRound(processId, delays, round + 1, onSuccess, onError)
            break
        }
      }).catch(e => {
        onError(e)
      })
    }, this.isDelayPending ? delays[delays.length - 1] * 1000 : ProcessService.resolveDelay(delays, round))
  }

  /**
   * Method to get error message
   */
  static getErrorMessage (response: ProcessData): string {
    if (!response.data) {
      return 'Unknown error'
    }

    return !Array.isArray(response.data) ? response.data.message : response.data[0]
  }
}
